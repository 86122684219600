<template>
  <BasePopup ref="popupRef">
    <div class="popup-content">
      <BaseH1 :text="'Założ darmowe konto'" />
      <TextDescription :text="description" />
      <AuthRegistrationBlock />
    </div>
  </BasePopup>
</template>

<script>
import { ref } from "vue";

import BasePopup from "@/components/BasePopup.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import AuthRegistrationBlock from "@/components/Auth/Registration/AuthRegistrationBlock.vue";

export default {
  components: {
    BasePopup,
    BaseH1,
    TextDescription,
    AuthRegistrationBlock,
  },

  props: {
    description: {
      type: String,
      required: true,
    },
  },

  expose: ["open", "close"],

  setup() {
    const popupRef = ref(null);

    const open = () => {
      if (popupRef.value === null) return;
      popupRef.value.open();
    };

    const close = () => {
      if (popupRef.value === null) return;
      popupRef.value.close();
    };

    return {
      popupRef,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.popup-content {
  margin: auto;
  padding: 0px 60px;
  display: flex;
  flex-flow: column;
  row-gap: 65px;
}

@media (max-width: 1200px) {
  .popup-content {
    margin: auto;
    padding: 0px 30px;
    display: flex;
    flex-flow: column;
    row-gap: 50px;
  }
}
</style>
