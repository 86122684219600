import { googleInit, googleRemove, renderButton } from "@/services/googleAuth";
import { onMounted, onUnmounted, ref } from "vue";

export default function useGoogleSignIn(
  buttonOptions,
  handleCredentialResponse
) {
  const buttonRef = ref(null);

  const config = {
    context: "use",
    ux_mode: "popup",
    callback: handleCredentialResponse,
    auto_prompt: "false",
  };

  const onInit = () => {
    renderButton(buttonRef.value, buttonOptions);
  };

  onMounted(() => {
    googleInit(config).then(onInit);
  });

  onUnmounted(() => {
    googleRemove();
  });

  return {
    buttonRef,
  };
}
