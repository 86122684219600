<template>
  <ContainerModal ref="modalRef">
    <div class="popup">
      <slot />

      <BaseButtonIconCross @click="close" class="popup__delete-button" />
    </div>
  </ContainerModal>
</template>

<script>
import { ref } from "vue";

import ContainerModal from "@/layouts/ContainerModal.vue";
import BaseButtonIconCross from "@/components/UI/Base/BaseButtonIconCross.vue";

export default {
  components: { ContainerModal, BaseButtonIconCross },

  emits: ["opened", "closed"],

  expose: ["open", "close"],

  setup(_, { emit }) {
    const modalRef = ref(null);

    const open = () => {
      if (modalRef.value === null) return;
      modalRef.value.open();
      emit("opened");
    };

    const close = () => {
      if (modalRef.value === null) return;
      modalRef.value.close();
      emit("closed");
    };

    return {
      modalRef,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.popup {
  position: relative;
  width: var(--layout-view-wrapper-narrow--width);
  min-height: var(--layout-view-wrapper-narrow--min-height);
  padding: 50px 0px;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: var(--popup--bg-color);
  border-radius: var(--button-border-radius);
}
.popup__delete-button {
  position: absolute;
  top: 17px;
  right: 17px;
  opacity: 0;
  transition: opacity 0.2s;
  opacity: 0.5;
}
.popup__delete-button:hover {
  opacity: 1;
}

@media (max-width: 1200px) {
  .popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    min-height: 55vh;
    padding: 60px 0px;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: var(--popup--bg-color);
    border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
  }
  .popup__delete-button {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0;
    transition: opacity 0.2s;
    opacity: 0.5;
  }
}
</style>
