const { loadScript, removeScript } = require("@/helpers");

const SCRIPT_URL = "https://accounts.google.com/gsi/client";
const GOOGLE_SIGNIN_CLIENT_ID =
  "727993678974-jsf7i8isf4uihoapjm1ho032a5sh2t81.apps.googleusercontent.com";

let google = null;

const loadGoogleClientScript = async () => {
  await loadScript(SCRIPT_URL);
  google = window.google;
};

const removeGoogleClientScript = () => removeScript(SCRIPT_URL);

export const googleInit = async (config) => {
  await loadGoogleClientScript();
  if (google === null) throw new Error("Load google script first!");
  google.accounts.id.initialize({
    client_id: GOOGLE_SIGNIN_CLIENT_ID,
    ...config,
  });
};

export const googleRemove = () => {
  removeGoogleClientScript();
};

export const renderButton = (parent, options) => {
  if (google === null) throw new Error("Load google script first!");
  google.accounts.id.renderButton(parent, options);
};

export const showPromt = () => {
  if (google === null) throw new Error("Load google script first!");
  google.accounts.id.prompt();
};
