<template>
  <Teleport to="body">
    <div @click="close" v-if="isVisible" class="container-modal">
      <div @click.stop class="container-modal__content-wrapper">
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  expose: ["open", "close"],

  setup() {
    const isVisible = ref(false);

    const route = useRoute();
    // Close modal container on new route name
    // (i.e. if route was changed due to the link inside modal)
    watch(
      () => route.name,
      (newName, oldName) => {
        if (newName !== oldName && isVisible.value === true) {
          close();
        }
      }
    );

    const open = () => {
      document.body.classList.add("noscroll");
      isVisible.value = true;
    };

    const close = () => {
      document.body.classList.remove("noscroll");
      isVisible.value = false;
    };

    return { isVisible, open, close };
  },
};
</script>

<style scoped>
.container-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  display: flex;
  overflow-y: scroll;
  background-color: var(--modal--bg-color);
  cursor: pointer;
  overscroll-behavior: none;
  /* To prevent ios safari page scrolling */
  /* touch-action: none;
  -ms-touch-action: none; */
}
.container-modal__content-wrapper {
  width: fit-content;
  height: fit-content;
  margin: auto;
  display: flex;
  cursor: auto;
}
</style>
