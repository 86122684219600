export default function useAnonymusAdGuard(adTypeRef, adClassRef) {
  // TODO: change MAX_AD_CLICK_AMOUNT to 50
  const MAX_AD_CLICK_AMOUNT = 10;
  const OFFER_NANNY_CLICK_COUNT_KEY = "ANON_OFFER_NANNY_CLICK_COUNT";
  const APPLICATION_NANNY_CLICK_COUNT_KEY =
    "ANON_APPLICATION_NANNY_CLICK_COUNT";

  const canVisitAd = () => {
    const currentCount = _getAdClickCount();
    return currentCount < MAX_AD_CLICK_AMOUNT;
  };

  const countAdClick = () => {
    return localStorage.setItem(
      _getAdClickCountKey(),
      JSON.stringify(_getAdClickCount() + 1)
    );
  };

  const _getAdClickCount = () => {
    return JSON.parse(localStorage.getItem(_getAdClickCountKey())) || 0;
  };

  const _getAdClickCountKey = () => {
    if (adTypeRef.value === "offer") {
      if (adClassRef.value === "nanny") {
        return OFFER_NANNY_CLICK_COUNT_KEY;
      }
    }
    if (adTypeRef.value === "application") {
      if (adClassRef.value === "nanny") {
        return APPLICATION_NANNY_CLICK_COUNT_KEY;
      }
    }
    throw new Error(
      `Provide valid adTypeRef.value and adClassRef.value! Received adTypeRef.value '${adTypeRef.value}' and adClassRef.value '${adClassRef.value}'`
    );
  };

  return { canVisitAd, countAdClick };
}
