<template>
  <div class="section-auth">
    <ButtonAuthGoogleSignUp
      :handleCredentialResponse="handleCredentialResponse"
    />
    <div class="container-email">
      <TextDescription>Lub uzyj</TextDescription>
      <ButtonLink :to="routeRegistrationEmail" :label="'Adres e-mail'" />
    </div>
  </div>
</template>

<script>
import useAuth from "@/composables/useAuth";

import {
  ROUTE_REGISTRATION_EMAIL_NAME,
  ROUTE_REGISTRATION_IDENTIFICATION_NAME,
} from "@/router/constants";

import ButtonAuthGoogleSignUp from "@/components/UI/ButtonAuthGoogleSignUp.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import { useRouter } from "vue-router";

export default {
  components: {
    ButtonAuthGoogleSignUp,
    TextDescription,
    ButtonLink,
  },

  setup() {
    const routeRegistrationEmail = {
      name: ROUTE_REGISTRATION_EMAIL_NAME,
    };
    const routeRegistrationIdentification = {
      name: ROUTE_REGISTRATION_IDENTIFICATION_NAME,
    };

    const { handleGoogleResponseOnPreRegistration } = useAuth();
    const router = useRouter();

    const handleCredentialResponse = (res) => {
      handleGoogleResponseOnPreRegistration(res);
      router.push(routeRegistrationIdentification);
    };

    return {
      handleCredentialResponse,
      routeRegistrationEmail,
    };
  },
};
</script>

<style scoped>
.section-auth {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 55px;
}
.container-email {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 12px;
}
</style>
